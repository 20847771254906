import React from "react"
import styled from "styled-components"
import { device } from "../styles/device"

const aboutData = [
  { year: `2014`, text: `Kaywon Univercity of Art & Design Graduation` },
  {
    year: `2015 ~ 2019`,
    text: `Diredctorfilm Production Assistant Director
    `,
  },
  { year: `2019 ~`, text: `CONTINEW storyboard Artist` },
]

const ContactPage = () => {
  return (
    <>
      <Wrapper>
        {/* <CareerArea /> */}
        <BioArea />
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  font-family: "Roboto Condensed", sans-serif;
  color: #eaeaea;
  opacity: 0.8;
  text-decoration: none;
  margin: 7rem 0;
  
  @media ${device.mobileL} {
    margin: 9rem 0;

  }

  a:link,
  a:visited {
    color: #eaeaea;
  }
`

const BioArea = () => {
  return (
    <BioWrapper>
      <Name>전룡</Name>
      <Title>STORYBOARD ARTIST</Title>
      <Contact>
        <tr>
          <td>
            <a href={`tel:010-3400-2372`}>010.3400.2372</a>
          </td>
        </tr>
        <tr>
          <td>
            <a href={`mailto:planotoid@gmail.com`}>planotoid@gmail.com</a>
          </td>
        </tr>
      </Contact>
      <Career>
        {aboutData.map((data, idx) => {
          return (
            <tr key={idx}>
              <TableYear>{data.year}</TableYear>
              <TableText>{data.text}</TableText>
            </tr>
          )
        })}
      </Career>
    </BioWrapper>
  )
}

const BioWrapper = styled.div`
  border-left: 1px solid #eaeaea;
  padding-left: 8px;
`

const Title = styled.h4`
  font-weight: normal;
  margin-top: 0.5rem;
  font-size: 14px;
`

const Name = styled.h2`
  font-size: 19px;
  letter-spacing: 2px;
  margin: 2rem 0 0;

  @media ${device.mobileL} {
    font-size: 21px;

  }
`

const Contact = styled.table`
  margin-bottom: 1rem;
  font-size: 12px;


  @media ${device.mobileL} {
    font-size: 14px;

  }
  
`

const Career = styled.table`
  font-size: 12px;

  @media ${device.mobileL} {
    font-size: 14px;

  }
`

const TableYear = styled.td`
  padding: 0 0 4px 0;
`

const TableText = styled.td`
  padding: 0 0 4px 8px;
`
export default ContactPage
